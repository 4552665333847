import { useRevalidator } from '@remix-run/react';
import { useEffect, useRef, useState } from 'react';
import { match } from 'ts-pattern';

import {
  type DtoProgression,
  EnumsMediaType,
} from '@lp-lib/api-service-client/public';
import { MediaFormatVersion } from '@lp-lib/media';

import { useLiveCallback } from '../../hooks/useLiveCallback';
import { useQueryParam } from '../../hooks/useQueryParam';
import { apiService } from '../../services/api-service';
import { type Game, type GamePack } from '../../types/game';
import { fromMediaDTO } from '../../utils/api-dto';
import { getStaticAssetPath } from '../../utils/assets';
import { booleanify } from '../../utils/common';
import { ImagePickPriorityHighToLow, MediaUtils } from '../../utils/media';
import { CopyButton } from '../common/CopyButton';
import { Tooltip } from '../common/Tooltip';
import { GamePackCoverImage } from '../Game/GamePackCoverPres';
import { FilledCheckIcon } from '../icons/CheckIcon';
import { CopyLinkIcon } from '../icons/CopyLinkIcon';
import { DefaultLogoIcon } from '../icons/LogoIcon';
import { useOrgBrandColor } from '../VenueOrgLogoAverageColor/useOrgBrandColor';
import { TrainingLogo } from './design/TrainingLogo';

function LevelIcon(props: {
  brandColor?: string;
  status: 'locked' | 'in-progress' | 'completed';
  isLast: boolean;
  hovered: boolean;
}) {
  const { status, brandColor, isLast, hovered } = props;

  const fillColor = match(status)
    .with('completed', () => '#39D966')
    .otherwise(() => brandColor || '#FBB707');

  return (
    <div
      className={`relative w-[58px] h-13.5 lg:w-20 lg:h-[73px] flex justify-center items-center`}
    >
      <div className='absolute bottom-0'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          className='w-full'
          viewBox='0 0 58 35'
        >
          <ellipse cx='29' cy='20' rx='29' ry='15' fill={fillColor} />
          <ellipse
            cx='29'
            cy='20'
            rx='29'
            ry='15'
            fill='black'
            fillOpacity='0.4'
          />
          <ellipse cx='29' cy='15' rx='29' ry='15' fill={fillColor} />
          <ellipse
            cx='29'
            cy='13.5'
            rx='24'
            ry='11.5'
            fill='black'
            fillOpacity={hovered && status !== 'locked' ? '0.1' : '0.2'}
          />
        </svg>
      </div>
      <div className='absolute top-0'>
        <img
          src={match(status)
            .with('locked', () =>
              isLast
                ? getStaticAssetPath('images/overworld/trophy.png')
                : getStaticAssetPath('images/overworld/lock.png')
            )
            .with('in-progress', () =>
              isLast
                ? getStaticAssetPath('images/overworld/animate-trophy.png')
                : getStaticAssetPath('images/overworld/flipping-star.png')
            )
            .with('completed', () =>
              getStaticAssetPath('images/overworld/green-checked.png')
            )
            .exhaustive()}
          alt=''
          className='w-11 h-11 lg:w-15 lg:h-15 object-contain'
        />
      </div>
    </div>
  );
}

function LevelButton(props: {
  pack: GamePack;
  index: number;
  game: Game;
  name?: React.ReactNode;
  status: 'locked' | 'in-progress' | 'completed';
  isLast: boolean;
  onClick?: () => void;
}) {
  const { index, game, name, status, isLast, onClick } = props;

  const ref = useRef<HTMLButtonElement>(null);
  const [hovered, setHovered] = useState(false);

  const { color } = useOrgBrandColor();

  useEffect(() => {
    if (status === 'in-progress') {
      ref.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [status]);

  return (
    <button
      ref={ref}
      type='button'
      className={`flex-none flex items-center gap-5 ${[
        'ml-15 lg:ml-28',
        'ml-6 lg:ml-13',
        '',
        'ml-6 lg:ml-13',
      ].at(index % 4)} ${
        status === 'locked' ? 'opacity-40 pointer-events-none' : ''
      } ${onClick ? '' : 'pointer-events-none'}`}
      onMouseEnter={() => {
        setHovered(true);
      }}
      onMouseLeave={() => {
        setHovered(false);
      }}
      onClick={onClick}
    >
      <div className='relative flex justify-center items-center'>
        <LevelIcon
          status={status}
          isLast={isLast}
          hovered={hovered}
          brandColor={color}
        />
        {status === 'in-progress' && (
          <div className='absolute bottom-full -mb-3 animatejit-[bounce_2s_infinite]'>
            <Tooltip
              position={'top'}
              backgroundColor='#101012'
              borderColor='#303436'
              borderWidth={1}
              borderRadius={8}
              arrowWidth={8}
            >
              <p
                className='w-20 lg:w-27 h-8 lg:h-9 text-sms lg:text-base  italic flex justify-center items-center'
                style={{
                  color,
                }}
              >
                {index === 0 ? 'Start' : 'Continue'}
              </p>
            </Tooltip>
          </div>
        )}
      </div>
      <div
        className={`max-w-40 lg:max-w-50 text-sms lg:text-base text-left line-clamp-3 p-0.5
          ${match(status)
            .with('locked', () => 'font-normal italic')
            .with('completed', () => 'font-normal italic')
            .with('in-progress', () => `font-bold`)
            .exhaustive()}
        `}
        style={{
          color: status === 'in-progress' ? color : 'white',
        }}
      >
        {name ?? game?.name}
      </div>
    </button>
  );
}

function Background(props: { pack: GamePack }) {
  const { pack } = props;

  const media = pack.marketingSettings?.lobbyBackground?.media;
  const url = MediaUtils.PickMediaUrl(fromMediaDTO(media), {
    priority: ImagePickPriorityHighToLow,
  });

  return !url ? null : (
    <div className='absolute inset-0'>
      {media?.type === EnumsMediaType.MediaTypeVideo ? (
        <video
          src={url}
          muted
          autoPlay
          loop
          className='w-full h-full object-cover object-center opacity-10'
        />
      ) : (
        <img
          src={url}
          alt=''
          className='w-full h-full object-cover object-center opacity-10'
        />
      )}
    </div>
  );
}

function CopyLinkButton() {
  return (
    <CopyButton
      copiedText={window.location.href}
      className='
        btn w-8 h-8 bg-black hover:bg-lp-gray-003 text-white 
        rounded-lg border border-secondary
        flex items-center justify-center
      '
    >
      <CopyLinkIcon className='w-4 h-4 fill-current' />
    </CopyButton>
  );
}

function ResetProgressButton(props: { pack: GamePack }) {
  const revalidator = useRevalidator();
  const handleReset = useLiveCallback(async () => {
    await apiService.progression.resetMyProgression(props.pack.id);
    revalidator.revalidate();
  });

  return (
    <button
      type='button'
      className='btn text-primary underline text-sms'
      onClick={handleReset}
    >
      Reset Progress
    </button>
  );
}

export function Overworld(props: {
  pack: GamePack;
  games: Game[];
  // this is an escape hatch for the editor view to render a reactive game name. avoiding passing in the raw stores here.
  gameNames?: React.ReactNode[];
  progression?: DtoProgression;
  isPreview?: boolean;
  onClickGame?: (game: Game) => void;
}) {
  const { pack, games, gameNames, progression, onClickGame } = props;

  const showResetProgressButton = booleanify(
    useQueryParam('show-reset-progress-button')
  );
  const progressIndex =
    games.findIndex((game) => !progression?.progress?.[game.id]?.completedAt) ||
    0;
  const allGamesCompleted = games.every(
    (game) => progression?.progress?.[game.id]?.completedAt
  );

  return (
    <div className={`relative w-full h-full text-white`}>
      <Background pack={pack} />

      <div className='relative z-10 w-full h-full overflow-auto scrollbar-hide flex flex-col items-center'>
        <div className='flex-none w-full h-12.5 lg:h-15 px-3 flex items-center'>
          <DefaultLogoIcon className='w-8 h-8' />
        </div>

        <div className='relative flex-none w-full max-w-108 bg-white bg-opacity-10 sm:rounded-2.5xl p-4 flex flex-col gap-4'>
          <div className='w-full flex items-center justify-between'>
            <div>
              <TrainingLogo withBrandColor={false} />
            </div>
            {!props.isPreview && <CopyLinkButton />}
          </div>

          <div className='flex items-center gap-3.5'>
            {pack.cover && (
              <GamePackCoverImage
                pack={pack}
                alt={''}
                preferredFormatVersion={MediaFormatVersion.MD}
                className='flex-none w-24 h-13 rounded'
              />
            )}

            <div className='text-xl lg:text-2xl font-black line-clamp-2'>
              {pack.name}
            </div>
          </div>

          <div className='flex-none flex justify-end'>
            {showResetProgressButton && <ResetProgressButton pack={pack} />}
          </div>

          {allGamesCompleted && (
            <div className='absolute -bottom-5 left-1/2 -translate-x-1/2'>
              <div className='w-36 h-8 bg-green-001 text-white rounded-xl flex items-center justify-center gap-1'>
                <FilledCheckIcon className='w-4 h-4 fill-current' />
                Completed
              </div>
            </div>
          )}
        </div>

        <div
          className={`w-full flex-1 overflow-auto scrollbar-hide pl-8 pr-2 ${
            progressIndex === 0 ? 'pt-12' : 'pt-8'
          } flex flex-col items-center`}
        >
          <div className='flex-none flex flex-col gap-5'>
            {games.map((game, index) => {
              const progress = progression?.progress?.[game.id];
              const status = progress?.completedAt
                ? 'completed'
                : index === progressIndex
                ? 'in-progress'
                : 'locked';

              return (
                <LevelButton
                  key={game.id}
                  index={index}
                  pack={pack}
                  game={game}
                  name={gameNames?.[index]}
                  status={status}
                  isLast={index === games.length - 1}
                  onClick={onClickGame ? () => onClickGame(game) : undefined}
                />
              );
            })}
          </div>
          <div className='flex-none h-4' />
        </div>
      </div>
    </div>
  );
}
